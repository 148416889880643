import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Shop from "./pages/Shop";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import "./index.css";
import { AppStore } from "./constants/Stores";
import ErrorBoundary from "./pages/ErrorBoundry";
import AdminPanel from "./pages/AdminPanel";
import AdminRoute from "./components/AdminRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/orima-admin",
    element: (
      <AdminRoute>
        <AdminPanel />
      </AdminRoute>
    ),
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/payment_success",
    element: <h4>Payment Success</h4>,
  },
  {
    path: "/payment_error",
    element: <h4>Payment Error</h4>,
  },
  {
    path: "*",
    element: <NotFound />, 
  },
]);

const UseLayout = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {

    try {
      
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        AppStore.setState({ isMobile: window.innerWidth <= 768 });
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    } catch (error) {
      console.log('resize error', error)
    }


  }, []);
  return null;
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <UseLayout />
    <RouterProvider router={router} />
  </ErrorBoundary>
);

reportWebVitals();
