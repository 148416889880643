// CombModel.tsx
import React, { Suspense, useMemo } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Html,
  OrbitControls,
  Preload,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { AppStore } from "../constants/Stores";
import "./Model.css";

/** Preload the model outside of any component to avoid re-fetches. */
useGLTF.preload("/models/comb.glb");

const Loader = () => {
  const { progress } = useProgress();

  return (
    <Html center className="model-loader self-center place-self-center">
      <div></div>
    </Html>
  );
};

const Model = React.memo(() => {
  const { scene } = useGLTF("/models/comb.glb");
  return <primitive object={scene} scale={[1, 1, 1]} />;
});

const Lights = React.memo(() => {
  return (
    <>
      {/* Front light */}
      <rectAreaLight
        width={2.5}
        height={2.5}
        color="#fff"
        intensity={45}
        position={[0, 0, 5]}
        penumbra={1}
      />
      {/* Ambient fill */}
      <hemisphereLight intensity={1} groundColor="white" />
    </>
  );
});

const CombModel= () => {
  const isMobile = AppStore((state) => state.isMobile);

  /**
   * Memoizing these ensures they don't trigger re-renders
   * unless `isMobile` changes.
   */
  const autoRotateSpeed = useMemo(() => (isMobile ? 8.5 : 2.5), [isMobile]);
  const meshProps = useMemo(
    () => ({
      rotation: [3.1442, 0, -4.71],
      position: [-0.0065, 0.0075, 0],
    }),
    []
  );

  return (
    <Canvas
      className="canvas-container"
      // Consider `frameloop="always"` if you want continuous auto-rotate
      frameloop="demand"
      camera={{ position: [-4, 0, -3], fov: 1 }}
      gl={{ preserveDrawingBuffer: true }}
    >
            <rectAreaLight
        width={2.5}
        height={2.5}
        color={"#fff"}
        intensity={45}
        position={[0, 0, 5]}
        penumbra={1}
        // castShadow
      />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        enableDamping={false}
        dampingFactor={0.05}
        maxPolarAngle={Math.PI * 2}
        minPolarAngle={Math.PI / 4}
        autoRotate
        autoRotateSpeed={autoRotateSpeed}
        target={[0, 0, -0.006]}
      />

      <Suspense fallback={<Loader />}>
        <mesh {...meshProps}>
          <Lights />
            <Model />
        </mesh>
      </Suspense>

      <Preload all />
    </Canvas>
  );
};

export default CombModel;
